/**
 * Contrôle la présence du cookie pour l'affichage des boutons raccourcis depuis le mobile
 * @returns {number} 1 (true) ou 0
 */
function checkMobShortcuts() {
  var name = 'cookie_shorts_hc='
  var t = document.cookie.split(';')
  for (var i=0; i<t.length; i++) {
    var c = t[i]
    while (c.charAt(0) == ' ')
      c = c.substring(1)
    if ( c.indexOf(name) != -1 )
      var checkCookie = c.substring(name.length, c.length)
  }
  return checkCookie
}

/**
 * Fonctionnalités principale au chargement d'une page
 */
export function handle() {
  var $s = $('#shorts'),
      up = 'icon-up',
      dn = 'icon-down'
  if ( checkMobShortcuts() == 1 ) {
    $s.removeClass('show').addClass('hide')
    $s.find('.toggler i').removeClass(dn).addClass(up)
  }
  $s.find('.toggler').on('click', function() {
    $s.toggleClass('hide show')
    $s.find('.toggler i').toggleClass(up + ' ' + dn)
    if ( $s.hasClass('show') )
      document.cookie = 'cookie_shorts_hc=0; expires=Fri, 31 Dec 2999 23:59:59 GMT; path=/'
    else
      document.cookie = 'cookie_shorts_hc=1; expires=Fri, 31 Dec 2999 23:59:59 GMT; path=/'
  })
}
