/* fichier JS de base commun quelques soient les versions du projet web */

// charge bootstrap nécessite jquery et popper
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/util'
// import 'bootstrap' // directement déclaré dans le fichier de config webpack base
/* global lazySizes */
import 'lazysizes'
// import 'lazysizes/plugins/aspectratio/ls.aspectratio'

// pas de ./ car on résout dans theme donc déjà déclaré dans resolve.modules dans webpack.base.js
// l'extension n'est pas obligatoire car connue dans resolve.extensions, ne fonctionnera pas si il y a des fichiers de différentes extensions portant le même nom (ex: bootstrap.css et bootstrap.js dans le même dossier)
// import 'bootstrap/scss/bootstrap'
import './scss/_bootstrap_custom'

// Styles custom
import './scss/custom'

// Scripts librairies
import { handle } from './js/libs/misc'
import { tClick } from './js/libs/utils'
// import './js/components/cookie.js'
// lazy loading https://github.com/aFarkas/lazysizes
lazySizes.cfg.expand = 80
lazySizes.cfg.expFactor = 1
$('body').removeClass('no-js')

// lazysizes : add simple support for background images :
document.addEventListener('lazybeforeunveil', function (e) {
  const bg = e.target.getAttribute('data-bg')
  if (bg) e.target.style.backgroundImage = 'url(' + bg + ')'
})

// Quick House Models Search Form inside header
/*
async function getQhmFormOut ($f) {
  $f.load()
  // webpackChunkName: "quick-house-models-search-form"
  import('./js/components/quick-house-models-search-form.js').then(() => {
    $f.load(false)
  })
}
*/

// Quick Search Modal
async function getQsModal ($f) {
  $f.load()
  import(/* webpackChunkName: "quick-search-modal" */ './js/components/quick-search-modal.js').then(() => {
    $f.load(false)
  })
}

// My selection
async function getMyselection ($f, open = false, $add = false) {
  $f.load()
  import(/* webpackChunkName: "my-selection" */ './js/components/my-selection.js').then(module => {
    const form = module.mySelection
    form.init(open)
    if ($add !== false) { $add.trigger('click') }
    $f.load(false)
  })
}

// Scroll to top
async function getScrollToTop () {
  import(/* webpackChunkName: "scrollToTop" */ './js/libs/scrollToTop/scrollToTop.js').then(() => {
    $.scrollUp({
      easingType: 'linear',
      topSpeed: 300,
      scrollSpeed: 400,
      animation: 'fade',
      scrollText: '<i class="icon-up"></i>'
    })
  })
}

// sticky header
function sticky (_w) {
  const _h = $('#header')
  const s = 'sticky'
  if ((_w.scrollTop() > 32 && _w.width() > 991) || (_w.scrollTop() > 0 && _w.width() <= 991)) { _h.addClass(s) } else { _h.removeClass(s) }
}

// Actions principales communes à toutes les pages
$(document).ready(function () {
  const _w = $(window)

  // sur le scroll
  _w.scroll(function () {
    if (_w.scrollTop() >= 100 && !$('#scrollUp').length) { getScrollToTop() }
    sticky(_w)
  })

  const _h = $('#header')
  // const _qhmf = '.qhmForm.out'
  _h.find('.dropdown-toggle').on('mouseenter click', function () {
    $(this).parent().find('.dropdown-menu').addClass('d-block')
    /*
    if ($(this).parent().find(_qhmf).length && !$(_qhmf).hasClass('loaded')) {
      getQhmFormOut($(_qhmf))
    }
    */
  })

  // bouton mon espace
  $('.myspace').on('click', function () {
    window.open($(this).attr('data-href'))
  })

  // bouton recherche rapide
  $('.qsModal').on('click', function () {
    $(this).off()
    getQsModal($(this))
  })

  // bouton ma sélection
  $('.cart-button').on('click', function () {
    $(this).off()
    getMyselection($('.cart-button'), true)
  })

  // boutons wish add
  $('.add-wish').on('click', function (e) {
    e.stopPropagation()
    getMyselection($('.cart-button'), false, $(this))
  })

  // navbar toggler
  $('[data-trigger]').on('click', function () {
    $($(this).attr('data-trigger')).toggleClass('show')
    $('.navbar-overlay').toggleClass('visible')
  })
  $('.navbar-close, .navbar-overlay').click(function () {
    $('.navbar-collapse').removeClass('show')
    $('.navbar-overlay').removeClass('visible')
  })

  // boutons liens
  $('[data-toggle="link"]').on('click', function () {
    if ($(this).attr('data-target') === 'self') { document.location.href = $(this).attr('data-href') } else { window.open($(this).attr('data-href')) }
  })

  // ancre
  $('.scroll').each(function () {
    const $this = $(this)
    $this.on('click', function (e) {
      const t = ($(e.target).is('a')) ? this.hash : $(this).attr('target')
      e.preventDefault()
      if ($this.length > 0) {
        $('html, body').animate({
          scrollTop: ($(t).offset().top - 80) - -1
        }, 500)
      }
    })
  })

  handle()
  sticky(_w)
  tClick()
})
