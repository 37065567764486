// Charge la base commune
import './common.js'

import './scss/pages/home.scss'

// Scripts librairies
/* global Waypoint */
import 'waypoints/lib/jquery.waypoints.min.js'

// rafraîchissement
function r () {
  Waypoint.refreshAll()
}

// Mainslider
async function getMainslider () {
  import(/* webpackChunkName: "mainslider", webpackPrefetch: true */ './js/components/mainslider.js').then(() => {
    r()
  })
}

// OwlCarousel
async function getOwlCarousel () {
  import(/* webpackChunkName: "owl-carousel" */ './js/components/owl-carousel.js').then(() => {
    r()
  })
}

// Tabs
async function getTabs ($f) {
  import(/* webpackChunkName: "tabs", webpackPrefetch: true */ './js/components/tabs.js').then(() => {
    $f.load(false)
    const $e = $('.qosForm')
    const $t = $('.offers-tab')
    $t.on('shown.bs.tab', function () {
      // e.target // newly activated tab
      $e.load()
      getQosForm($e)
      $t.off('shown.bs.tab')
    })
  })
}

// Quick House Models Search Form
async function getQhmFormIn ($f) {
  import(/* webpackChunkName: "quick-house-models-search-form", webpackPrefetch: true */ './js/components/quick-house-models-search-form.js').then(() => {
    $f.load(false)
  })
}

// Quick Offers Search Form
async function getQosForm ($f) {
  import(/* webpackChunkName: "quick-offers-search-form" */ './js/components/quick-offers-search-form.js').then(() => {
    $f.load(false)
  })
}

// Newsletter Form
async function getNsForm ($f) {
  import(/* webpackChunkName: "newsletter-form" */ './js/components/newsletter-form.js').then(() => {
    $f.load(false)
  })
}

// Offer autocomplete
async function getOfferAutocomplete ($f) {
  import(/* webpackChunkName: "offer-autocomplete" */ './js/components/offer-autocomplete.js').then(module => {
    const form = module.offersAutocomplete
    form.init($f)
  })
}

// Agency autocomplete
async function getAgencyAutocomplete ($f) {
  import(/* webpackChunkName: "quick-agency-search-form" */ './js/components/quick-agency-search-form.js').then(module => {
    const form = module.qasForm
    form.init($f)
  })
}

// Contact Form
async function getContactForm ($f) {
  import(/* webpackChunkName: "contact-form" */ './js/components/contact-form.js').then(() => {
    $f.load(false)
    r()
  })
}

// actions principales communes à toutes les pages
$(document).ready(function () {
  // gestion du slider principal
  const $a = $('.owl-carousel.mainslider')
  if ($a.length) {
    $a.waypoint(function () {
      getMainslider()
      // console.log('mainslider')
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // gestion des tabs
  const $b = $('.tabs')
  if ($b.length) {
    $b.waypoint(function () {
      $b.load()
      getTabs($b)
      // console.log('tabs')
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // gestion du formulaire de recherche des modèles
  const $c = $('.qhmForm.in')
  if ($c.length) {
    $c.waypoint(function () {
      $c.load()
      getQhmFormIn($c)
      // console.log('qhmForm')
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // gestion du formulaire de recherche des offres
  const $e = $('.qosForm.in')
  if ($e.length) {
    $e.waypoint(function () {
      // console.log(this.element.id + ' hit')
      $e.load()
      getQosForm($e)
      // console.log('qosForm')
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // gestion du formulaire de newsletter
  const $f = $('.newsletter-form.in')
  if ($f.length) {
    $f.waypoint(function () {
      $f.load()
      getNsForm($f)
      // console.log('newsletter-form')
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // gestion des carousel
  const $d = $('.owl-carousel.c')
  if ($d.length) {
    $d.waypoint(function () {
      getOwlCarousel()
      // console.log('owl-carousel')
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // gestion du formulaire de contact
  const $z = $('.contact-form')
  if ($z.length) {
    $z.waypoint(function () {
      $z.load()
      getContactForm($z)
      // console.log('contact-form')
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // gestion cta
  const $cta = $('.cta')
  if ($cta.length) {
    $cta.waypoint(function () {
      import(/* webpackChunkName: "cta" */ './scss/components/cta.scss').then(() => {
        Waypoint.refreshAll()
      })
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // refresh waypoints au chargement d'une image
  $('.lazyload').on('lazyloaded', () => {
    r()
  })

  // filtres des offres autocomplete
  $('form[name="qosForm"] input[name="search"]').on('click', function () {
    $(this).blur().off('click').attr('disabled', '')
    getOfferAutocomplete($(this).parents('form'))
  })

  // filtres des agences autocomplete
  $('form[name="qasForm"] input[name="search"]').on('click', function () {
    $(this).blur().off('click').attr('disabled', '')
    getAgencyAutocomplete($(this).parents('form'))
  })
})
