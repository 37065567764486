/**
 * Etend une zone de clic vers une page de destination
 * la zone d'extension doit porter la classe .t-click
 * l'url à suivre doit être sur un lien href et porter la classe .t-url
 */
var tClickimclicked = false
export function tClick() {
  $('.t-click').off('click').on('click', function (e) {
    if ( !$(e.target).is('a') ) { // ne s'applique pas sur le clic d'un a.href sinon clic 2 fois sur le lien sur firefox par exemple et c'est problématique avec des target="_blank"
      if ( !tClickimclicked ) {
        var a = $(this).find('a.t-url')
        a.get(0).click()
      }
    }
  })
  $('.t-click a.t-url').off('click').on('click', function (e) {
    tClickimclicked = true
    setTimeout(function() {
      tClickimclicked = false
    }, 100)
  })
}

/**
 * Affiche un loader à un élément
 * @param {boolean} i = true Affiche ou non le loader
 */
$.fn.load = function(i = true) {
  if (i) {
    this.find('.load').stop(true, true).animate({opacity: .5}, 200)
    this.find('input, button, textarea, select').prop('disabled', true)
    $(this.find('.processing')[0]).stop(true, true).show().animate({opacity: 1}, 200)
    //this.find('.processing').stop(true, true).show().animate({opacity: 1}, 200)
  } else {
    this.addClass('loaded')
    this.find('.load').stop(true, true).animate({opacity: 1}, 200)
    this.find('input, button, textarea, select').prop('disabled', false)
    $(this.find('.processing')[0]).stop(true, true).animate({opacity: 0}, 200, function() { $(this).hide() })
  }
}

/**
 * Sur la visibilité d'un élément
 * @param   {[[Type]]} oldCallback [[Description]]
 * @returns {[[Type]]} [[Description]]
 */
//var _oldShow = $.fn.show
//$.fn.show = function(oldCallback) {
//  return $(this).each(function() {
//    var obj = $(this),
//        newCallback = function() {
//          if ($.isFunction(oldCallback)) {
//            oldCallback.apply(obj)
//          }
//          obj.trigger('afterShow')
//        }
//
//    // you can trigger a before show if you want
//    obj.trigger('beforeShow')
//
//    // now use the old function to show the element passing the new callback
//    _oldShow.apply(obj, [newCallback])
//  })
//}

//function preload(i = true) {
//  if (i) {
//    _this.$f.stop(true, true).animate({opacity: 0.5}, settings.animation.duration).prop('disabled', true)
//    _this.$f.find('input, button, textarea, select').prop('disabled', true)
//    _this.$f.find('.form-process').stop(true, true).show().animate({opacity: 1}, settings.animation.duration)
//  } else {
//    _this.$f.stop(true, true).animate({opacity: 1}, settings.animation.duration).prop('disabled', false)
//    _this.$f.find('input, button, textarea, select').prop('disabled', false)
//    _this.$f.find('.form-process').stop(true, true).animate({opacity: 0}, settings.animation.duration, function() { $(this).hide() })
//  }
//}

// Wait while GMap API is loading
export function dtectMap(action) {
  if ( $('body').hasClass('maploaded') ) {
    //console.log('maploaded')
    action()
  } else {
    setTimeout(function() {
      dtectMap(action)
    }, 200)
  }
}
